import { Fragment, useState, useEffect } from "react";
import Loader from "../components/Loader";
import ChooseMountainPage from "./ChooseMountain";
import ChooseLevelPage from "./ChooseLevel";
import ChooseAvatarPage from "./ChooseAvatar";
import ChooseTeamsPage from "./ChooseTeams";
import { useLocation, useNavigate } from "react-router-dom";
import { createMountain, createUser, getLists } from "../api/listApi";
import { Icon } from "@iconify/react";

export default function OptionsPage() {

    const [newUser, setNewUser] = useState(false);
    const [countQuestions, setCountQuestions] = useState(4);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>("");
    const [mountainList, setMountainList] = useState<any>([]);

    const navigation = useNavigate();
    const { state } = useLocation();
    const bg = {
        backgroundImage: 'url(/images/bg3.png)',
    };

    const [dataUser, setDataUser] = useState<any>(
        {
            avatar: 0,
            level: 0,
            mountain: 0,
            team: 0
        }
    );

    useEffect(() => {
        if(state) {
            if(state.exist) {
                setNewUser(false);
                setCountQuestions(2);
                setDataUser({
                    ...dataUser,
                    name: state.data.name,
                    email: state.email,
                });

                let mountainsData = state.mointains;
                setMountainList(mountainsData);
                console.log("montañas",mountainsData);
                


            } else {
                setNewUser(true);
                setCountQuestions(4);
                setDataUser({
                    ...dataUser,
                    name: state.data.name,
                    email: state.email,
                });
            }
        }
    }, []);

    const next = () => {
        if(step < countQuestions) {
            if(!loading) setStep(step + 1);
        }
        if(step == countQuestions) {
            setLoading(true);
            if(newUser) {
                console.log(dataUser);
                createUser(dataUser).then((res: any) => {
                    console.log(res);
                    if(res.code == "100"){
                        localStorage.setItem("user_c1ir1d1an", JSON.stringify(res));
                        setTimeout(() =>  navigation("/dashboard") , 100);
                    } else {
                        setLoading(false);
                        setError("Error, please try again later.");
                    }
                }).catch((err: any) => {
                    setLoading(false);
                    console.log(err);
                    setError("Error, please try again later.");
                });
            } else {
                createMountain(dataUser).then((res: any) => {
                    console.log(res);
                    if(res.code  == "100"){
                        localStorage.setItem("user_c1ir1d1an", JSON.stringify(res));
                        setTimeout(() =>  navigation("/dashboard") , 100);
                    } else {
                        setLoading(false);
                        setError("Error, please try again later.");
                    }
                }).catch((err: any) => {
                    setLoading(false);
                    console.log(err);
                    setError("Error, please try again later.");
                });
            }
        }
    }

    const handleAvatar = (id: any) => {
        setDataUser({
            ...dataUser,
            avatar: id
        });        
    }
    const handleTeam = (id: any) => {
        setDataUser({
            ...dataUser,
            team: id
        });
    }
    const handleLevel = (id: any) => {
        setActualLevel(id);
        setDataUser({
            ...dataUser,
            level: id
        });
    }
    const handleMountain = (id: any) => {
        setDataUser({
            ...dataUser,
            mountain: id
        });
    }

    const [actualLevel, setActualLevel] = useState<any>(0);

    return (
        <div className="content-wrap no-select" style={bg}>
            <div className="progress">
                <div className="progress-bar" style={{height: `${step * (100/(countQuestions))}%`}}></div>
            </div>
            <div className="inner-content inner-options">
                {step > 1 && (
                    <div className="back pointer" onClick={()=>setStep(step - 1)}>
                        {/* <img src="/icons/arrows-back.svg" /> */}
                        <Icon icon="material-symbols:arrow-back-ios" />
                         Back
                    </div>
                )}
                {newUser && (
                    <>
                    {step == 1 && <ChooseAvatarPage optionSelected={(e: any)=>handleAvatar(e)} />}
                    {step == 2 && <ChooseTeamsPage optionSelected={(e: any)=>handleTeam(e)} />}
                    {step == 3 && <ChooseLevelPage optionSelected={(e: any)=>handleLevel(e)} />}
                    {step == 4 && <ChooseMountainPage mountainList={mountainList} optionSelected={(e: any)=>handleMountain(e)} level={actualLevel} />}
					{/* <div className="page"><ChooseAvatarPage optionSelected={(e: any)=>handleAvatar(e)} /></div>
                    <div className="page"><ChooseTeamsPage optionSelected={(e: any)=>handleTeam(e)} /></div>
                    <div className="page"><ChooseLevelPage optionSelected={(e: any)=>handleLevel(e)} /></div>
                    <div><ChooseMountainPage mountainList={mountainList} optionSelected={(e: any)=>handleMountain(e)} level={actualLevel} /></div> */}
                    </>
                )}
                {!newUser && (
                    <>
                    {step == 1 && <ChooseLevelPage optionSelected={(e: any)=>handleLevel(e)} />}
                    {step == 2 && <ChooseMountainPage mountainList={mountainList} optionSelected={(e: any)=>handleMountain(e)} level={actualLevel} />}
                    </>
                )}
                <div className={`next-btn transition ${step == countQuestions ? 'ready' : ''} `} onClick={()=>next()} >
                    {step == countQuestions ? <img src="/images/ready.png" alt="" /> : <img src="/images/next.png" alt="" />}
                </div>
                {error.length > 0 && (
                    <div className="error">
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
}