import { useEffect, useRef, useState } from "react";
import data from "../data/data";


export default function GeneralStat() {

    const bgStat = {backgroundImage: 'url(/images/bg5.png)'}
		const pasosFinal = 50000;
    const ref = useRef<SVGSVGElement>(null);
    const refDiv = useRef<HTMLDivElement>(null);
    const refMountain = useRef<HTMLDivElement>(null);
    const refBarsIndividual = useRef<SVGSVGElement>(null);
    const refBarsTeam = useRef<SVGSVGElement>(null);

    const d3 = require("d3");

    const [mountains, setMountains] = useState([]);

    const [team, setTeam] = useState([]);
		const [info, setInfo] = useState<any>(data.generalTrack);
    //create array with colors for 20 users
    const colors = ["#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#0000FF", "#4B0082", "#9400D3", "#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#0000FF", "#4B0082", "#9400D3", "#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#0000FF", "#4B0082", "#9400D3"];


    return (
        <div className="inner-content-stat team" style={bgStat}>
            <div className="container-graph">
                <div className="title-stat">
                    GENERAL TRACKING
                </div>
                <div className="hello-stat">
                    EVEREST
                </div>
								<table>
									<thead>
										<tr>
											<th>Mountain</th>
											<th>General Rank</th>
											<th>Team</th>
											<th>Name Rank</th>
											<th>Name</th>
										</tr>
									</thead>
									<tbody>
										{
											info.map((item: any, index: any)=>
											<tr key={index}>
												<td className="col">{item.mountain}</td>
												<td className="col">{item.rank}</td>
												<td className="col">{item.team}</td>
												<td className="col">{item.name_rank}</td>
												<td className="graphs">
												{ item.users.map((user:any, index: any)=> 
													
													<div className="item" key={index}>
														<span className="name">{user.name}</span>
														<div className="item-graph"  style={{ width: `${(user.steps / pasosFinal) * 100}%`}}>
															<span>{user.steps}</span>
														</div>
													</div>
												)
												}
												</td>
											</tr>
											)
										}
									</tbody>
								</table>
				
            </div>
        </div>
    )
}