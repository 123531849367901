import { useState, Key } from "react";
import data from "../data/data";

export default function ChooseAvatarPage({ optionSelected }: any) {

    const [levels, setLevels] = useState<any>(data.avatarData);

    const [selectedLevel, setSelectedLevel] = useState<any>(0);

    const selectOption = (id: any) => {
        setSelectedLevel(id);
        optionSelected(id);
    }

    return (
        <div className="contain-option animate__animated animate__fadeIn">
            <div className="title-option">
                SELECT YOUR AVATAR
            </div>
            <div className="content-option avatars">
                {levels.map((level: any, index: Key) => (
                    <div 
                    className={selectedLevel == level.id ? "avatar transition pointer selected-avatar" : "avatar transition pointer"} 
                    key={index}
                    onClick={() => selectOption(level.id)}
                    >
                        <div className="selected-icon animate__animated animate__bounceIn">
                            <img src="/icons/check.png" className="icon" />
                        </div>
                        <img src={level.image} />
                    </div>
                ))}
            </div>
        </div>
    );
}