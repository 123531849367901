import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { checkEmail } from "../api/listApi";
import { useNavigate } from "react-router-dom";

export default function NewPage() {

    const [isLogin, setIsLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [needName, setNeedName] = useState(false);

    const navigate = useNavigate();

    const next = () => {
        setError("");
        if(!isLogin) {
            setIsLogin(true);
        } else {
            setLoading(true);
            if(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                checkEmail(email).then((res: any) => {
                    console.log("res", res);
                    if(res.code == "100") {
                        setMsg(`WELCOME BACK ${res.user.name}`);
                        localStorage.setItem("user_c1ir1d1an", JSON.stringify(res));
                        if(res.inProgress){
                            setTimeout(() => navigate("/dashboard", { state: { exist: true, data: res } }), 2000);
                        } else {
                            setTimeout(() => navigate("/choose-options", { state: { exist: false, data: res, email: email } }), 2000);
                        }
                    } else {
                        setMsg("WELCOME NEW USER");
                        setNeedName(true);
                    }
                }).catch((err: any) => {
                    console.log("err", err);
                    setLoading(false);
                    setError("Something went wrong");
                });
            } else {
                setError("Please enter a valid email");
                setLoading(false);
            }
        }
    }

    const nextWithName = () => {
        setError("");
        setMsg(`THANK YOU ${name}`);
        setTimeout(() => navigate("/choose-options", { state: { exist: false, data: {name:name}, email: email } }), 2000);
    }

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("LOGIN");

		const bannerStyle = {
			backgroundImage: 'url(/imgages/banner.jpg)',
		};

    const handleEmail = (e: any) => {
        setEmail(e.target.value);
        setError("");
    }
    const handleName = (e: any) => {
        setName(e.target.value);
        setError("");
    }

    useEffect(() => {
        localStorage.removeItem("user_c1ir1d1an");
    }, []);

    return (
        <div className="content-wrap no-select">
            {/* <div className="background">
                <div className="overlayer"></div>
                <video autoPlay muted loop>
                    <source src="/video/bg.mp4" type="video/mp4" />
                </video>
            </div> */}
            <div className="inner-content inner-home">
                {!isLogin && (
                    <>
											<div className="banner-home" style={bannerStyle}>
												<div className="title-home">
															<h1>TOP OF THE WORLD</h1>
															<h2>CISCO SECURITY - 14 PEAKS CHALLENGE</h2>
													</div>
													<div className="logos-home">
															<div className="img">
																	<img src="/images/cisco-logo.png" alt="Cisco Security" />
															</div>
															<div className="img">
																	<img src="/images/iridian-logo.png" alt="Iridian Consulting" />
															</div>
													</div>
													<div className="instructions-home">
															<div className="title-ins">INSTRUCTIONS</div>
															<div className="text-ins">
																	In 14 peaks challenge you will have to log in with your email, select the mountain <br />you want to complete, the difficulty level, an avatar and that's it! You will be able to <br />challenge yourself daily and meet the goal of steps to complete your mountain.
															</div>
													</div>
													
											</div>
                    </>
                )}
                <div className={isLogin ? "box-login transition1 opened" : "box-login transition1"}>
                    {isLogin && (
                        <>
                            <div className="inner-box">
                                <div className="t animate__animated animate__fadeInDown animate__slow">{msg}</div>
                                {!needName && (
                                    <div className="form animate__animated animate__fadeIn">
                                        <div className="label">EMAIL</div>
                                        <input type="email" name="email" placeholder="EMAIL" value={email} onChange={handleEmail} />
                                    </div>
                                )}
                                {needName && (
                                    <div className="form animate__animated animate__fadeIn">
                                        <div className="label">PLEASE ENTER YOUR NAME</div>
                                        <input type="text" name="name" placeholder="NAME" value={name} onChange={handleName} />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {!needName &&
                    <div className="btn pointer transition" onClick={()=>next()}>
                        {loading ?  <Loader type={"1"} />  : "NEXT"}
                    </div>}
                    {needName && <div className="btn pointer transition" onClick={()=>nextWithName()}>CONTINUE</div>}
                    {error && (
                        <div className="error">{error}</div>
                    )}
                </div>
            </div>

        </div>
    );
}