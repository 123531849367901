import { useState, Key, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Icon } from '@iconify/react';
import 'swiper/css';
import "swiper/css/grid";
import "swiper/css/navigation";
import { Grid, Navigation } from "swiper";
import data from "../data/data";

export default function ChooseMountainPage({optionSelected, mountainList, level}: any) {

    const [mountains, setMountains] = useState<any>(data.mountainsData);

    const [selectedMountain, setSelectedMountain] = useState<any>(0);

    const [actualLevel, setActualLevel] = useState<any>({});

    const selectOption = (id: any, used: boolean) => {
        if(!used) {
            setSelectedMountain(id);
            optionSelected(id);
        }
    }

    const getIfMountainIsUsed = (id: any) => {        
        let isUsed = false;
        if(mountainList.length > 0) {
            mountainList.map((mountain: any) => {
                if(mountain.id == id) isUsed = true;
            });
        }
        return isUsed;
    }

    useEffect(() => {
        if(level) {
            let levelsData = data.levelsData;
            let levelFiltered = levelsData.filter((item: any) => item.id == level);
            console.log(levelFiltered);
            if(levelFiltered.length > 0) {
                setActualLevel(levelFiltered[0]);
            }
        }
    }, [level]);

    return (
        <div className="contain-option contain-mountain animate__animated animate__fadeIn">
            <div className="title-option">
                CHOOSE THE MOUNTAIN OF YOUR TEAM<br />
                <span className="subtitle">Level: {actualLevel.name} {actualLevel.id} </span>
            </div>
            <div className="content-option slider">
            <Swiper
                spaceBetween={30}
                slidesPerView={1}
                //grid={{rows: 2, fill: 'row'}}
                onSlideChange={() => {}}
                onSwiper={(swiper: any) => {}}
                navigation={true}
                modules={[Navigation]}
                // breakpoints={{
                //     320: {
                //         slidesPerView: 1,
                //         spaceBetween: 20,
                //     },
                //     640: {
                //         slidesPerView: 3,
                //         spaceBetween: 30,
                //     },
                // }}
                >
                {
                    mountains.map((item: any, index: Key) => 
                        <SwiperSlide key={index}>
                            <div className={`item-option transition pointer ${selectedMountain === item.id ? 'selected' : ''} ${getIfMountainIsUsed(item.id) ? 'used' : ''}`} 
                            onClick={() => selectOption(item.id, getIfMountainIsUsed(item.id))}>
																<div className="title-img transition">
																	{item.name}
																</div>
                                <div className="info">
                                    <div className="col-info">
															
																				<Icon className="icon" icon="ic:sharp-location-on" />
                                        {item.location}
                                    </div>
                                    <div className="col-info">
																				<Icon className="icon" icon="fa-solid:arrows-alt-v"/>
                                        ({item.altitude} KM)
                                    </div>
                                    <div className="col-info">
																				<Icon className="icon" icon="fa-solid:arrows-alt-v"/>
                                        ({item.altitudeft} FT)
                                    </div>
                                    <div className="col-info">
																				<Icon className="icon" icon="ion:footsteps"/>
                                        {item.levels[actualLevel.id] || 0 }
                                    </div>
                                </div>
																<div className="img">
                                    <img src={item.image} alt="Mountain" />
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                }
            </Swiper>
            </div>
        </div>
    );
}