import { useState, Key } from "react";
import Loader from "../components/Loader";
import data from "../data/data";


export default function ChooseTeamsPage({optionSelected}: any) {

    const [teams, setTeams] = useState<any>(data.teamsData);

    const [selectedTeam, setSelectedTeam] = useState<any>(0);

    const selectOption = (id: any) => {
        setSelectedTeam(id);
        optionSelected(id);
    }
    

    return (
        <div className="contain-option animate__animated animate__fadeIn">
            <div className="title-option ">
                SELECT YOUR TEAM
            </div>
            <div className="content-option teams-box">
                {teams.map((team: any, index: Key) => (
                        <div 
                        className={selectedTeam == team.id ? "team-option transition pointer selected-team" : "team-option transition pointer"} 
                        key={index}
                        onClick={() => selectOption(parseInt(team.id))}
                        >
                            {/* <div className="option-selected"></div> */}
                            {team.name}
                        </div>
                    ))
                }
            </div>
        </div>
    );
}