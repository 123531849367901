import { Key, useEffect, useRef, useState } from "react";
import { getStepsByTeam } from "../api/listApi";
import data from "../data/data";



export default function TeamStat({steps, actualData}: any) {

		const bgStat = {backgroundImage: 'url(/images/bg5.png)'}
    
    const ref = useRef<SVGSVGElement>(null);
    const refDiv = useRef<HTMLDivElement>(null);


    const d3 = require("d3");

    const [team, setTeam] = useState<any>([]);
    //create array with colors for 20 users
    const colors = ["#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#0000FF", "#4B0082", "#9400D3", "#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#0000FF", "#4B0082", "#9400D3", "#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#0000FF", "#4B0082", "#9400D3"];

    useEffect(() => {
        
        if(actualData){
            getStepsByTeam(actualData.user.team).then((res: any) => {
                if(res.code == "100"){
                    let usersList: any = [];
                    res.users.forEach((user: any, index: number) => {
                        let userArray: any = {
                            id: user.id,
                            name: user.name,
                            avatar: getDataFromId(user.avatar, data.avatarData)?.image,
                            values: []
                        }
                        let totalStepsPerUser = 0;
                        let arraySteps: any = [];
                        user.tracking.forEach((tracking: any, indextracking: number) => {
                            
                            tracking.steps.forEach((step: any, index: number) => {
                                arraySteps.push({x: new Date(step.day), y: parseInt(step.steps)});
                            });
                            
                        });
                        //order by date
                        arraySteps.sort((a: any, b: any) => a.x - b.x);
                        userArray.values = arraySteps;
                        usersList.push(userArray);
                    });
                    setTeam(usersList);
                    setTimeout(() => {
                        drawGraph(usersList);
                    }, 1000);
                    
                }
            }); 
        }


        
    }, []);

    const drawGraph = (list: any) => {

        //get width and height of div
        let w = refDiv.current?.clientWidth || 500;
        let h = refDiv.current?.clientHeight || 500;

        const margin = {top: 10, right: 0, bottom: 50, left: 50};
        const width = w - (margin.left + margin.right);
        const height = h - (margin.top + margin.bottom);

        //clear svg
        d3.select(ref.current).selectAll("*").remove();

        const svg = d3.select(ref.current)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

        //get the min max in date  from data
        let minDate = d3.min(list, (d: any[]) => d3.min(d.values, (v: { x: any; }) => v.x));
        let maxDate = d3.max(list, (d: any[]) => d3.max(d.values, (v: { x: any; }) => v.x));

        let minY = d3.min(list, (d: any[]) => d3.min(d.values, (v: { y: any; }) => v.y));
        let maxY = d3.max(list, (d: any[]) => d3.max(d.values, (v: { y: any; }) => v.y));
        

        var xScale = 
        d3.scaleTime()
        .domain([minDate, maxDate])
        .range([0, width]);
        // d3.scaleLinear()
        // .domain([0,  12])
        // .range([0, width]);

        var yScale = d3.scaleLinear()
        .domain([0, maxY])
        .range([height, 0]);

        var line = d3.line()
        .x(function(d: { x: any; }) { return xScale(d.x); })
        .y(function(d: { y: any; }) { return yScale(d.y); });

        //add axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + (height ) + ")")
            .attr("class", "axisx")
            .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%d %b %Y")));

        svg.append("g")
            .attr("transform", "translate(" + margin.left + ",0)")
            .attr("class", "axisy")
            .call(d3.axisLeft(yScale));

        let containerGraph = svg.append("g")
            .attr("class", "contentGraphMain")
            .attr("transform", "translate(" + margin.left + ",0)");

        console.log("list dsdsgsdg",list);

        list.forEach((element: any, index: any) => {
            containerGraph.append("path")
            .attr("d", line(element.values))
            .attr("stroke", colors[index])
            .attr("fill", "none");
        });

        // containerGraph.selectAll("path")
        //     .data(team)
        //     .enter()
        //     .append("path")
        //     .attr("d", function(d: any[]) { return line(d.values); })
        //     .attr("stroke", function(d: any, index: any) { return colors[index]; })
        //     .attr("fill", "none");
            

        //add grid lines with color grey and opacity 0.2
        svg.append("g")
            .attr("class", "grid")
            .attr("transform", "translate(" + margin.left + "," + (height ) + ")")
            .call(d3.axisBottom(xScale)
                .tickSize(-height)
                .tickFormat("")
            )
            .attr("opacity", 0.1);

        svg.append("g")
            .attr("class", "grid")
            .attr("transform", "translate(" + margin.left + ",0)")
            .call(d3.axisLeft(yScale)
                .tickSize(-width)
                .tickFormat("")
            )
            .attr("opacity", 0.1);
    }

    //On resize
    useEffect(() => {
        window.addEventListener("resize", ()=>setTimeout(() =>  drawGraph(team) , 1000));
        return () => window.removeEventListener("resize", ()=>setTimeout(() =>  drawGraph(team) , 1000));
    }, []);

    const getDataFromId = (id: any, data: any) => {
        const result = data.find((item: any) => item.id == id);
        return result;
    }

    
    return (
        <div className="inner-content-stat team" style={bgStat}>
					<div className="container-graph">
					<div className="title-stat">
                TEAM TRACKING
            </div>
            <div className="hello-stat">
                EVEREST
            </div>
            <div className="graphs-box">
                <div className="header-graph r">
                    {
                        team.map((item: any, index: any ) => (
                            <div className="item-header-graph" key={index}>
                                <div className="avatar">
                                    <img src={item.avatar} />
                                </div>
                                <div className="name">
                                    <div className="point" style={{background: colors[index]}}></div> {item.name}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="graph-container" ref={refDiv}>
                    <svg ref={ref} ></svg>
                </div>
            </div>
					</div>
        </div>
    )
}