import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NewPage from "./pages/NewPage";
import OptionsPage from "./pages/OptionsPage";
import StatPage from "./pages/StatPage";

export function RoutesList() {

    return (
        <>
            <Routes>
                <Route  path="/" element={<HomePage />} />
                <Route  path="/new" element={<NewPage />} />
                <Route  path="/choose-options" element={<OptionsPage />} />
                <Route  path="/dashboard" element={<StatPage />} />
            </Routes>
        </>
    );
}