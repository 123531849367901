import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { checkEmail } from "../api/listApi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Parallax, Pagination } from "swiper";

export default function HomePage() {
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [needName, setNeedName] = useState(false);

  const navigate = useNavigate();

  const next = () => {
    setError("");
    if (!isLogin) {
      setIsLogin(true);
    } else {
      setLoading(true);
      if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        checkEmail(email)
          .then((res: any) => {
            console.log("res", res);
            if (res.code === "100") {
              setMsg(`WELCOME BACK ${res.user.name}`);
              localStorage.setItem("user_c1ir1d1an", JSON.stringify(res));
              if (res.inProgress) {
                setTimeout(
                  () =>
                    navigate("/dashboard", {
                      state: { exist: true, data: res },
                    }),
                  2000
                );
              } else {
                setTimeout(
                  () =>
                    navigate("/choose-options", {
                      state: { exist: false, data: res, email: email },
                    }),
                  2000
                );
              }
            } else {
              setMsg("WELCOME NEW USER");
              setNeedName(true);
            }
          })
          .catch((err: any) => {
            console.log("err", err);
            setLoading(false);
            setError("Something went wrong");
          });
      } else {
        setError("Please enter a valid email");
        setLoading(false);
      }
    }
  };

  const nextWithName = () => {
    setError("");
    setMsg(`THANK YOU ${name}`);
    setTimeout(
      () =>
        navigate("/choose-options", {
          state: { exist: false, data: { name: name }, email: email },
        }),
      2000
    );
  };

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("LOGIN");

  const bannerStyle = {
    backgroundImage: "url(/images/banner.jpg)",
  };

  const bannerIns = {
    backgroundImage: "url(/images/intro1.png)",
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
    setError("");
  };
  const handleName = (e: any) => {
    setName(e.target.value);
    setError("");
  };

  useEffect(() => {
    localStorage.removeItem("user_c1ir1d1an");
  }, []);

  return (
    <div className="content-wrap no-select">
      <div className="inner-content inner-home">
        {!isLogin && (
          <>
            <div className="banner" style={bannerStyle}>
              <div className="banner-home">
                <div className="logos">
                  <img
                    className="logo-1"
                    src="/images/cisco-logo-1.png"
                    alt=""
                  />
                  <img
                    className="logo-2"
                    src="/images/cisco-logo-2.jpg"
                    alt=""
                  />
                </div>
                <div className="title-home">
                  <h1>
                    <span className="first">14 PEAKS</span> <br /> 14 WEEKS{" "}
                    <br /> <span>CHALLENGE</span>
                  </h1>
                  <h2>NOTHING IS IMPOSSIBLE</h2>
                </div>
                <div className="logos-home">
                  <p>Power by:</p>
                  <img
                    src="/images/iridian-logo.png"
                    alt="Iridian Consulting"
                  />
                </div>
                <div className="button">
                  <Link to="ins" smooth={true} duration={1500}>
                    <img src="/images/start.png" alt="btn-start" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="instructions-home">
              <a id="ins">
                <div className="title-ins">INSTRUCTIONS</div>
              </a>
              {/* <img className="intro" src="/images/intro1.png" alt="" /> */}
              <Swiper
                speed={600}
                parallax={true}
                pagination={{
                  clickable: true,
                }}
                slidesPerView={4}
                navigation={true}
                modules={[Parallax, Pagination, Navigation]}
                className="mySwiper"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                  },
                }}
              >
                <div
                  slot="container-start"
                  className="parallax-bg"
                  style={bannerIns}
                  data-swiper-parallax="-23%"
                ></div>
                <SwiperSlide></SwiperSlide>
                <SwiperSlide></SwiperSlide>
                <SwiperSlide></SwiperSlide>
                <SwiperSlide></SwiperSlide>
                <SwiperSlide></SwiperSlide>
              </Swiper>
              <div className="btn-login">
                <Link to="login" smooth={true} duration={1000}>
                  <img src="/images/login.png" alt="btn" />
                </Link>
              </div>
            </div>
          </>
        )}
        <div className="box-login" id="login">
          <div className="box-container">
            <div className="inner-box">
              <div className="t animate__animated animate__fadeInDown animate__slow">
                14 PEAKS <br />
                <span>CALLENGE</span>
              </div>
              {!needName && (
                <div>
                  <div className="form animate__animated animate__fadeIn">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmail}
                    />
                    <div
                      className="btn pointer transition"
                      onClick={() => next()}
                    >
                      {loading ? <Loader type={"1"} /> : "Login"}
                    </div>
                    <div onClick={() => setNeedName(true)} className="link">
                      Register
                    </div>
                  </div>
                </div>
              )}
              {needName && (
                <div className="form animate__animated animate__fadeIn">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmail}
                  />
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={handleName}
                  />
                  <div
                    className="btn pointer transition"
                    onClick={() => nextWithName()}
                  >
                    Register
                  </div>
                  <div className="link" onClick={() => setNeedName(false)}>
                    Login
                  </div>
                </div>
              )}
            </div>
            <img
              className="img-mountain"
              src="/images/mountain-login.png"
              alt=""
            />
          </div>
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </div>
  );
}
