import { useEffect, useState } from "react";
import HomeStat from "./HomeStat";
import TeamStat from "./TeamStat";
import GeneralStat from "./GeneralStat";
import { checkEmail, getSteps } from "../api/listApi";
import data from "../data/data";

export default function StatPage() {

    const [actualView, setActualView] = useState("home");
    const [mountainCount, setMountainCount] = useState(Array.from(Array(data.mountainsData.length).keys()));
    const [actualMountains, setActualMountains] = useState(0);
    const [totalSteps, setTotalSteps] = useState(0);

    const [actualData, setActualData] = useState<any>({
        user: false,
        mountain: [],
        team: {},
        tracking: {}
    });

    const logout = () => {
        localStorage.removeItem("user_c1ir1d1an");
        window.location.href = "/";
    }

    const [steps, setSteps] = useState({tracking: {}});

    useEffect(() => {
        const session = JSON.parse(localStorage.getItem("user_c1ir1d1an") || "{tracking:{}}");
        console.log(session);
        processData(session);
    }, []);

    const updateSession = () => {
        checkEmail(actualData.user.email).then((res: any) => {
            console.log("res", res);
            if(res.code == "100") {
                localStorage.setItem("user_c1ir1d1an", JSON.stringify(res));
                processData(res);
            }
        })
    }

    const processData = (session: any) => {
        if(session.user){
            let total = 0;
            let actual = {
                user: session.user,
                mountain: session.tracking ? session.tracking.map((item: any) => {
                    let mountain = getDataFromId(parseInt(item.mountain), data.mountainsData);
                    let level = getDataFromId(parseInt(item.level), data.levelsData);
                    mountain.level = level;
                    mountain.steps = item.steps;
                    total += item.steps;
                    return mountain;
                }): [],
                team: getDataFromId(parseInt(session.user.team), data.teamsData),
                tracking: session.tracking
            }
            setTotalSteps(total);
            setActualData(actual);
            setActualMountains(actual.mountain.length);
            getSteps(session.user.email).then((res: any) => {
                if(res.code == "100"){
                    setSteps(res.tracking);
                }
            });
        } else {
            window.location.href = "/";
        }
    }

    const getDataFromId = (id: any, data: any) => {
        const result = data.find((item: any) => item.id == id);
        return result;
    }

		const getNameFromId = (id: any, data: any) => {
			let result = getDataFromId(id, data);
			if (result) {
					return result.name;
			}
			return "No data";
		}

    const getActualMountain = () => {
        let actual = actualData.mountain[actualData.mountain.length - 1];
        return actual;
    }

	

    return (
        <div className="content-wrap no-select content-full">
            <div className="inner-content inner-stat">
                <div className="header">
                    {/* <div className={`header-left pointer ${actualView == "home" && "selectMenu"}`}
                    onClick={() => setActualView("home")}>
                        <img src="/icons/home.svg" />
                    </div> */}
                    <div className="header-right">
                        {/* <div className={`header-right-item pointer ${actualView == "team" && "selectMenu"}`}
                        onClick={() => setActualView("team")}>
                            TEAM TRACKING 
                        </div>
                        <div className={`header-right-item pointer ${actualView == "general" && "selectMenu"}`}
                        onClick={() => setActualView("general")}>
                            GENERAL TRACKING
                        </div> */}
                        <div className="header-right-item pointer">
                            <div className="avatar">
                                <img src={getDataFromId(actualData.user.avatar, data.avatarData)?.image} alt="Avatar" />
                                <div className="name">{actualData.user.name}</div>
                            </div>
                            <div className="logout" onClick={logout}>
                            LOG OUT
                            </div>     
                        </div>
                    </div>
                </div>
                <div className="menu">
                   <div className="item" onClick={() => setActualView("home")}>
                        <img className="icon-mountain" src="/images/svg/mountain.svg" alt="" />
                        <div className="item-name">
                            <span>MOUNTAIN</span> <br />
                            <span className="opt">{getActualMountain()?.name}</span>
                        </div>
                    </div>
                    <div className="item">
                        <img className="icon-level" src="/images/svg/level.svg" alt="" />
                        <div className="item-name">
                            <span>LEVEL</span> <br />
                            <span className="opt"> {getNameFromId(getActualMountain()?.level.id, data.levelsData)}</span>
                        </div>
                    </div> 
                    <div className="item" onClick={() => setActualView("general")}>
                        <img className="icon-tracking" src="/images/svg/tracking.svg" alt="" />
                        <div className="item-name">
                            <span>TRACKING</span> <br />
                            <span className="opt">Individual</span>
                        </div>
                    </div>
                    <div className="item" onClick={() => setActualView("team")}>
                        <img className="icon-tracking1" src="/images/svg/trackingTeam.svg" alt="" />
                        <div className="item-name">
                            <span>TRACKING</span> <br />
                            <span className="opt">Team</span>
                        </div>
                    </div> 
                </div>
                <div className="content-stat">
                    {actualView == "home" && <HomeStat steps={steps} totalSteps={totalSteps} actualMountains={actualMountains} mountainCount={mountainCount} actualData={actualData} update={updateSession} />}
                    {actualView == "team" && <TeamStat steps={steps} actualData={actualData} />}
                    {actualView == "general" && <GeneralStat />}
                </div>
            </div>
        </div>
    );
}