import { Fragment, useState, useEffect } from "react";
import "../scss/Loader.scss";

export default function Loader({type}: any) {

    if(type == 1)
    return (
        <div className="loader2">
            <div className="circle circle1"></div>
            <div className="circle circle2"></div>
            <div className="circle circle3"></div>
            <div className="circle circle4"></div>
        </div>
    )
    else
    return (
        <div className="loader1"></div>
    )
}