import { Key, useEffect, useState } from "react";
import Loader from "../components/Loader";
import data from "../data/data";
import { addSteps } from "../api/listApi";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

export default function HomeStat({steps, actualData, mountainCount, actualMountains, totalSteps, update}: any)  {

    const [stepByDay, setStepByDay] = useState<any>(0);
    const [stepByWeek, setStepByWeek] = useState<any>(0);
    const [mountains, setMountains] = useState<any>([]);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [msgUpdate, setMsgUpdate] = useState("");
    const [canAddNewMountain, setCanAddNewMountain] = useState(false);
    
    const bgHome = {backgroundImage: 'url(/images/bg4.png)'}

    const navigate = useNavigate();

    
    useEffect(() => {
        console.log(actualData, "actualData", );
        console.log(steps, "steps");
        

        //order actualData.mountain by id asc
        let mountains = actualData.mountain.sort((a: any, b: any) => 
            a.id - b.id
        );
        setMountains(mountains);
        
        let totalWeek = 0;
        let totalDay = 0;
        if(steps.length > 0){
            steps[0].steps.forEach((item: any) => {
                totalDay += parseInt(item.steps); 
            });
            steps[0].steps.filter((item: any, index: any) => {
                let thisweek = new Date(item.day);
                let lastweek = new Date();
                lastweek.setDate(lastweek.getDate() - 7);
                return thisweek >= lastweek;
            } ).forEach((item: any) => {
                totalWeek += parseInt(item.steps);
            });
            let average = totalDay / steps[0].steps.length;
            setStepByDay(average || 0);
            setStepByWeek(totalWeek || 0);
        }
        setStepByWeek(totalWeek);

        //check if all mountain is complete
        let canAdd: any = [];
        let stepsForComplete = actualData.mountain.length;
        actualData.mountain.forEach((item: any) => {
            let foosteps = parseInt(item.footsteps.replace(".",""));
            if(item.steps >= foosteps){
                canAdd.push({id: item.id, steps: item.steps});
            }
        });        
        if(canAdd.length == stepsForComplete){
            console.log(canAddNewMountain, "canAdd");
            setCanAddNewMountain(true);
        } else {
            setCanAddNewMountain(false);
        }
    }, [steps]);

    const getDataFromId = (id: any, data: any) => {
        const result = data.find((item: any) => item.id == id);
        return result;
    }

    const getNameFromId = (id: any, data: any) => {
        let result = getDataFromId(id, data);
        if (result) {
            return result.name;
        }
        return "No data";
    }

    const getActualMountain = () => {
        let actual = actualData.mountain[actualData.mountain.length - 1];
        return actual;
    }

    const getMountainFromIndex = (index: any) => {
        let actual = mountains[index];
        return actual;
    }


    const [stepsForAdd, setStepsForAdd] = useState<any>("");

    const addStepToUser = () => {
        setLoadingUpdate(true);
        setMsgUpdate("");
        let actualTracking = actualData.tracking[0];
        console.log(actualTracking, "actualTracking");
        let json = {
            trackingId : actualTracking.id,
            steps : stepsForAdd
        }
        addSteps(json).then((res: any) => {
            console.log(res, "res");
            setLoadingUpdate(false);
            setStepsForAdd("");
            setMsgUpdate("Steps added");
            update();
        });
            
    }

    const addNewMountain = () => {
        setTimeout(() => navigate("/choose-options", { state: { exist: true, data: actualData.user, email: actualData.user.email, mointains: actualData.mountain } }), 2000);
    }

    if (!actualData.user) {
        return <Loader />
    }

    return (
        <div className="inner-content-stat" style={bgHome}>
            <div className="title-stat">
                {getNameFromId(actualData.user.team, data.teamsData).toUpperCase()}
            </div>
						<div className="mountain-name">
							{getActualMountain()?.name}
						</div>
						<div className="info-steps">
							<div className="col">
								<Icon className="icon-step" icon="material-symbols:calendar-month-outline" />
								<div className="label">WEEK TO CLIMB</div>
									<div className="text">
										11
											{/* {stepByWeek.toLocaleString(undefined, {maximumFractionDigits:2})} */}
                	</div>
							</div>
							<div className="col">
								<Icon className="icon-step" icon="ion:footsteps" />
								<div className="label">STEPS FOR DAY</div>
									<div className="text">
											{stepByDay.toLocaleString(undefined, {maximumFractionDigits:2})}
                	</div>
							</div>
							<div className="col">
								<Icon className="icon-step" icon="ion:footsteps" />
								<div className="label">STEPS FOR WEEK</div>
									<div className="text">
											{stepByWeek.toLocaleString(undefined, {maximumFractionDigits:2})}
                	</div>
							</div>
						</div>
            {/* <div className="hello-stat">
                HELLO {actualData.user.name}
            </div> */}
            {/* <div className="mountain-count">
                {mountainCount.map((item: number, index: Key) => (
                    <div className={`mountain-count-item ${actualMountains > item && "active"} `} key={index}>
                        <div className="img">
                            {
                                parseInt(getMountainFromIndex(item)?.steps) >= parseInt(getActualMountain()?.levels[getActualMountain().level.id].replace(".","")) ? (
                                    <img src="/icons/mountain-complete.svg" className="icon" />
                                ) : getMountainFromIndex(item)?.steps >= 0 ? (
                                    <img src="/icons/mountain-progress.svg" className="icon" />
                                ) : (
                                    <img src="/icons/mountain.svg" className="icon" />
                                )
                            }
                        </div>
    
                        <div className="num numt"> {getMountainFromIndex(item)?.name ? (item + 1) + ". " + getMountainFromIndex(item)?.name : (item + 1)}</div>
                        <div className="num">{getMountainFromIndex(item)?.level.name}</div>
                    </div>
                ))}
            </div> */}
            {
                canAddNewMountain && (
                <div className="add-mountain">
                    <div className="t-m">YOUR COMPLETE THIS MOUNTAIN!</div>
                    <div className="s-m">You have completed <b>{getActualMountain()?.name}</b> mountain, you can add new mountain.</div>
                    <div className="btn pointer" onClick={() => addNewMountain()}>
                        ADD MOUNTAIN +
                    </div>
                </div>    
                )
            }
            <div className="mountain">
                {/* <div className="item-mountain-box">
                    {actualData.mountain && (
                        <div className={`item-mountain transition pointer`}>
                            <div className="img">
                                <div className="title-img transition">
                                    <span>{getActualMountain()?.name}</span>
                                </div>
                                <img src={getActualMountain()?.image} alt="Mountain" />
                            </div>
                            <div className="info">
                                <div className="col-info">
                                    <img src="/icons/location.svg" className="icon" />
                                    {getActualMountain()?.location}
                                </div>
                                <div className="col-info">
                                    <img src="/icons/altitude.svg" className="icon" />
                                    ({getActualMountain()?.altitude} KM)
                                </div>
                                <div className="col-info">
                                    <img src="/icons/altitude.svg" className="icon" />
                                    ({getActualMountain()?.altitudeft} FT)
                                </div>
                                <div className="col-info">
                                    <img src="/icons/footsteps.svg" className="icon" />
                                    {getActualMountain()?.levels[getActualMountain().level.id]}
                                </div>
                            </div>
                        </div>
                    )}
                </div> */}
                <div className="info-mountain">
                    {/* <div className="row">
                        <div className="col">
                            <div className="label">YOUR LEVEL</div>
                            <div className="text">
                                {getNameFromId(getActualMountain().level.id, data.levelsData)}
                            </div>
                        </div>
                        <div className="col">
                            <div className="label">AVG STEPS FOR DAY</div>
                            <div className="text">
                                {stepByDay.toLocaleString(undefined, {maximumFractionDigits:2})}
                            </div>
                        </div>
                        <div className="col">
                            <div className="label">AVG STEPS FOR WEEK</div>
                            <div className="text">
                                {stepByWeek.toLocaleString(undefined, {maximumFractionDigits:2})}
                            </div>
                        </div>
                        <div className="col">
                            <div className="label">TARGET</div>
                            <div className="text">
                                {getActualMountain()?.levels[getActualMountain().level.id].toLocaleString(undefined, {maximumFractionDigits:2})}
                            </div>
                        </div>
                    </div> */}
                    <div className="row rowbottom">
                        {/* <div className="col">
                            <div className="label">RECORD YOUR STEPS OF THE WEEK</div>
                            <div className="input">
                                <input type="text" placeholder="Enter your steps" />
                            </div>
                        </div> */}
                        <div className="col">
                            <div className="input">
                                <input type="text" placeholder="Steps of the day" value={stepsForAdd} onChange={(e)=>setStepsForAdd(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {loadingUpdate ? (
                            <div className="btn inverted pointer"><Loader /></div>
                        ) : (
                            <div className="btn inverted pointer" onClick={addStepToUser}>Save</div>
                        )}
                    </div>
										{msgUpdate && <div className="msg">{msgUpdate}</div>}
										<img className="mountain-img" src={getActualMountain()?.image} alt="Mountain" />
                   
                </div>
            </div>
            
            {/* <div className="tracking">
                <div className="col">
                    <div className="avatar">
                        <img src={getDataFromId(actualData.user.avatar, data.avatarData)?.image} alt="Avatar" />
                        <div className="name">{actualData.user.name}</div>
                    </div>
                </div>
                <div className="col col-graph">
                    <div className="graph-circles">
                        <div className="graph-circle-1 animate__animated animate__zoomIn">
                            <div className="graph-circle-2 animate__animated animate__zoomIn animate_delay-1s">
                                <div className="graph-circle-3 animate__animated animate__zoomIn animate_delay-2s">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="row-legend">
                        <div className="circle-legend one"></div>
                        <div className="label"><b>YOUR LEVEL</b> <br /> {getNameFromId(getActualMountain().level.id, data.levelsData)} </div>
                    </div>
                    <div className="row-legend">
                        <div className="circle-legend two"></div>
                        <div className="label"><b>TOTAL MOUNTAINS</b> <br />{actualData.mountain.length} </div>
                    </div>
                    <div className="row-legend">
                        <div className="circle-legend three"></div>
                        <div className="label"><b>TOTAL STEPS</b> <br /> {totalSteps.toLocaleString(undefined, {maximumFractionDigits:2})} </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}