import axios from 'axios'
import { getEnvVariables } from './getEnvVariables'

const { API_URL, TokenAPI } = getEnvVariables()

const iridianApi = axios.create({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': 'Content-Type',
  baseURL: API_URL,
  withCredentials: false,
})

iridianApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
  }
  return config
})

export default iridianApi
