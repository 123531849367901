import { Fragment } from 'react';
import { BrowserRouter } from "react-router-dom";
import './App.scss';
import { RoutesList } from './Routes';

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <RoutesList />
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
