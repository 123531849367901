

const avatarData = [
    {
        id: 1,
        image: "/images/avatar/1.png"
    },
    {
        id: 2,
        image: "/images/avatar/2.png"
    },
    {
        id: 3,
        image: "/images/avatar/3.png"
    },
    {
        id: 4,
        image: "/images/avatar/4.png"
    },
    {
        id: 5,
        image: "/images/avatar/5.png"
    },
    {
        id: 6,
        image: "/images/avatar/6.png"
    },
    {
        id: 7,
        image: "/images/avatar/7.png"
    },
    {
        id: 8,
        image: "/images/avatar/8.png"
    },
    {
        id: 9,
        image: "/images/avatar/9.png"
    },
    {
        id: 10,
        image: "/images/avatar/10.png"
    },
    {
        id: 11,
        image: "/images/avatar/11.png"
    },
    {
        id: 12,
        image: "/images/avatar/12.png"
    },
];

const levelsData = [
    {
        id: 1,
        name: "Beginner",
        image: "/images/level/beginner.jpg",
        steps: 55000
    },
    {
        id: 2,
        name: "Medium",
        image: "/images/level/medium.jpg",
        steps: 82500 
    },
    {
        id: 3,
        name: "Expert",
        image: "/images/level/expert.jpg",
        steps: 165000

    },
    {
        id: 4,
        name: "Monstañist",
        image: "/images/level/montanist.jpg",
        steps: 495000
    }
];

const mountainsData = [
    {
        id: 1,
        name: "Everest",
        location: "Nepal /Tibet",
        altitude: "8.850",
        altitudeft: "29.035",
        footsteps: "55.000",
        image: "/images/mountains/routes/everest.png",
        levels: {
            "1": "55000",
            "2": "82500",
            "3": "165000",
            "4": "495000"
        }
    },
    {
        id: 2,
        name: "K2 GODWIN AUSTIN",
        location: "Pakistan / China",
        altitude: "8.611",
        altitudeft: "28.251",
        footsteps: "53.000",
        image: "/images/mountains/routes/k2.png",
        levels: {
            "1": "106000",
            "2": "159000",
            "3": "318000",
            "4": "954000"
        }
    },
    {
        id: 3,
        name: "Kangchenjunga",
        location: "Nepal / India",
        altitude: "8.586",
        altitudeft: "28.169",
        footsteps: "52.000",
        image: "/images/mountains/routes/kang.png",
        levels: {
            "1": "106000",
            "2": "159000",
            "3": "318000",
            "4": "954000"
        }
    },
    {
        id: 4,
        name: "Lhotse",
        location: "Nepal / Tibet",
        altitude: "8.516",
        altitudeft: "27.940",
        footsteps: "52.000",
        image: "/images/mountains/routes/lhotse.png",
        levels: {
            "1": "104000",
            "2": "156000",
            "3": "312000",
            "4": "936000"
        }
    },
    {
        id: 5,
        name: "Makalu",
        location: "Nepal / Tibet",
        altitude: "8.485",
        altitudeft: "27.825",
        footsteps: "51.500",
        image: "/images/mountains/routes/makalu.png",
        levels: {
            "1": "103000",
            "2": "154500",
            "3": "309000",
            "4": "927000"
        }
    },
    {
        id: 6,
        name: "Cho Oyu",
        location: "Nepal / Tibet",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "51.000",
        image: "/images/mountains/routes/cho.png",
        levels: {
            "1": "102000",
            "2": "153000",
            "3": "306000",
            "4": "918000"
        }
    },

    {
        id: 7,
        name: "Dhaulagiri",
        location: "Nepal",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "60.000",
        image: "/images/mountains/routes/dhaula.png",
        levels: {
            "1": "101000",
            "2": "151500",
            "3": "303000",
            "4": "909000"
        }
    },
    {
        id: 8,
        name: "Manaslu",
        location: "Nepal",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "51.000",
        image: "/images/mountains/routes/manaslu.png",
        levels: {
            "1": "101000",
            "2": "151500",
            "3": "303000",
            "4": "909000"
        }
    },
    {
        id: 9,
        name: "Nanga Parbat",
        location: "Pakistan",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "51.000",
        image: "/images/mountains/routes/nanga.png",
        levels: {
            "1": "101000",
            "2": "151500",
            "3": "303000",
            "4": "909000"
        }
    },
    {
        id: 10,
        name: "Annapurna I",
        location: "Nepal",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "51.000",
        image: "/images/mountains/routes/anna1.png",
        levels: {
            "1": "100000",
            "2": "150000",
            "3": "300000",
            "4": "900000"
        }
    },
    {
        id: 11,
        name: "Gasherbrum I",
        location: "Pakistan/China",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "51.000",
        image: "/images/mountains/routes/gashe1.png",
        levels: {
            "1": "100000",
            "2": "150000",
            "3": "300000",
            "4": "900000"
        }
    },
    {
        id: 12,
        name: "Broad Peak",
        location: "Pakistan/China",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "51.000",
        image: "/images/mountains/routes/broad.png",
        levels: {
            "1": "100000",
            "2": "150000",
            "3": "300000",
            "4": "900000"
        }
    },
    {
        id: 13,
        name: "Gasherbrum II",
        location: "Pakistan/China",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "51.000",
        image: "/images/mountains/routes/gashe2.png",
        levels: {
            "1": "100000",
            "2": "150000",
            "3": "300000",
            "4": "900000"
        }
    },
    {
        id: 14,
        name: "Shisha Pangma",
        location: "Tibet",
        altitude: "8.188",
        altitudeft: "26.864",
        footsteps: "51.000",
        image: "/images/mountains/routes/shisha.png",
        levels: {
            "1": "100000",
            "2": "150000",
            "3": "300000",
            "4": "900000"
        }
    },
];

const teamsData = [
    {
        id: 1,
        name: "Daily Walkers",
    },
    {
        id: 2,
        name: "StepOps",
    },
    {
        id: 3,
        name: "Are Fitbit Mandatory?",
    },
    {
        id: 4,
        name: "Gloabl Security Spice Girl (GSSG)",
    },
    {
        id: 5,
        name: "The B*A*S*H Team",
    },
    {
        id: 6,
        name: "Moral Supporters (aka the loners)",
    },
    {
        id: 7,
        name: "Indvidual Steppers",
    }
];

const generalTrack = [
    {
			mountain: 'Everest',
			rank: '1',
			team: 'Daily Walkers',
			name_rank: '3',
			users: [
				{
					name: 'Pablo O',
					steps: 30500
				},
				{
					name: 'Peter F.',
					steps: 20500
				},
				{
					name: 'Marle S.',
					steps: 7500
				},
				{
					name: 'Julia F.',
					steps: 25500
				},
				{
					name: 'Camille J',
					steps: 12500
				}
			]
    },
		{
			mountain: 'K2 Godwin Austen',
			rank: '1',
			team: 'Individual Steppers',
			name_rank: '3',
			users: [
				{
					name: 'Pablo O',
					steps: 40500
				},
				{
					name: 'Peter F.',
					steps: 15500
				},
				{
					name: 'Marle S.',
					steps: 17500
				},
				{
					name: 'Julia F.',
					steps: 45000
				},
				{
					name: 'Camille J',
					steps: 10000
				}
			]
    },
		{
			mountain: 'Kangchenjunga',
			rank: '1',
			team: 'Daily Walkers',
			name_rank: '3',
			users: [
				{
					name: 'Pablo O',
					steps: 20000
				},
				{
					name: 'Peter F.',
					steps: 10500
				},
				{
					name: 'Marle S.',
					steps: 1550
				},
				{
					name: 'Julia F.',
					steps: 35000
				},
				{
					name: 'Camille J',
					steps: 20500
				},
			]
    },
		{
			mountain: 'Lhotse',
			rank: '1',
			team: 'Daily Walkers',
			name_rank: '3',
			users: [
				{
					name: 'Pablo O',
					steps: 25000
				},
				{
					name: 'Peter F.',
					steps: 30500
				},
				{
					name: 'Marle S.',
					steps: 11550
				},
				{
					name: 'Julia F.',
					steps: 43000
				},
				{
					name: 'Camille J',
					steps: 12500
				},
			]
    },

]

export default {
    avatarData,
    levelsData,
    mountainsData,
    teamsData,
		generalTrack
}
