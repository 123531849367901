import { useState, Key } from "react";
import data from "../data/data";

export default function ChooseLevelPage({optionSelected}: any) {

    const [levels, setLevels] = useState<any>(data.levelsData);
    const [selectedLevel, setSelectedLevel] = useState<any>(0);

    const selectOption = (id: any) => {
        setSelectedLevel(id);
        optionSelected(id);
    }

    return (
        <div className="contain-option">
            <div className="title-option animate__animated animate__fadeIn">
                SELECT THE LEVEL OF DIFFICULTY
            </div>
            <div className="content-option cards animate__animated animate__fadeIn">
                {levels.map((level: any, index: Key) => (
                    <div 
                    className={`card-level transition pointer ${selectedLevel === level.id ? 'selected' : ''}`}
                    key={index} onClick={() => selectOption(level.id)}>
                        <div className="title">
                            <div>
                                <span className="name">{level.name}</span> <br />
                                <span className="steps">{level.steps}</span>
                            </div>
                        </div>
                        <img src={level.image} />
                    </div>
                ))}
            </div>
        </div>
    );
}