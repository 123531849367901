import iridianApi from './iridianApi';

export const checkEmail = async (email) => {
  try {
    email = email.replace(/[^a-zA-Z0-9@.]/g, '');
    const { data } = await iridianApi.get(`/checkEmail.php?email=${email}`).catch((error) => false);
    return data;
  } catch (error) {
    return error;
  }
}

export const getLists = async () => {
  try {
    const { data } = await iridianApi.get(`/getLists.php`).catch((error) => false);
    return data;
  } catch (error) {
    return error;
  }
}

export const getSteps = async (email) => {
  try {
    email = email.replace(/[^a-zA-Z0-9@.]/g, '');
    const { data } = await iridianApi.get(`/getSteps.php?email=${email}`).catch((error) => false);
    return data;
  } catch (error) {
    return error;
  }
}

export const getStepsByTeam = async (team) => {
  try {
    team = team.replace(/[^a-zA-Z0-9@.]/g, '');
    const { data } = await iridianApi.get(`/getStepsByTeam.php?team=${team}`).catch((error) => false);
    return data;
  } catch (error) {
    return error;
  }
}

export const createUser = async (json) => {
  try {
    const { data } = await iridianApi.post(`/newUser.php`, json).catch((error) => false);
    return data;
  } catch (error) {
    return error;
  }
}

export const createMountain = async (json) => {
  try {
    const { data } = await iridianApi.post(`/newMountain.php`, json).catch((error) => false);
    return data;
  } catch (error) {
    return error;
  }
}

export const addSteps = async (json) => {
  try {
    const { data } = await iridianApi.post(`/addSteps.php`, json).catch((error) => false);
    return data;
  } catch (error) {
    return error;
  }
}


export default {
  checkEmail,
  getLists,
  createUser,
  createMountain,
  getSteps,
  getStepsByTeam,
  addSteps,
}
